@font-face {
  font-family: "Nexa";
  src: url("/font/NexaBold.eot");
  src: url("/font/NexaBold.eot?#iefix") format("embedded-opentype"),
    url("/font/NexaBold.woff2") format("woff2"),
    url("/font/NexaBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nexa";
  src: url("/font/NexaLight.eot");
  src: url("/font/NexaLight.eot?#iefix") format("embedded-opentype"),
    url("/font/NexaLight.woff2") format("woff2"),
    url("/font/NexaLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "Nexa", sans-serif;
  color: #ffffff;
  background-color: #111111;
  background-image: url(/cast-background.svg);

  background-size: cover;
  background-attachment: fixed;

  min-height: 100vh;
  margin: 0;
  padding: 0;
  height: 100%;
}

body:has(.vmix) {
  background-image: none;
  background-color: transparent;
}

.card {
  border: none;
  background-color: #141414;
}

h4 {
  font-family: "Nexa", sans-serif;
  color: #fff;
}

h3 {
  font-family: "Nexa", sans-serif;
  color: #fff;
}

h5 {
  font-family: "Nexa", sans-serif;
  color: #fff;
}

p {
  font-size: 14px;
  line-height: 1.7;
  color: rgb(255, 255, 255);
  margin: 0;
}

a {
  font-size: 14px;
  line-height: 1.7;
  color: #000000;
  margin: 0;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #f43b1c;
}

.alert {
  border-radius: 25px;
  font-size: 15px;
  line-height: 35px;
  width: 100%;
  height: 35px;
  border-radius: 25px;
  padding: 0 30px 0 68px;
}

.btn-danger {
  background-color: #f43b1c;
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  color: #f43b1c;
}

/* FOOTER */

footer {
  bottom: 0px;
  width: 100%;
  height: 38px;
  line-height: 38px;
  color: #e6e6e6;
  vertical-align: middle;
  padding-top: 4px;
  text-align: center;
  position: absolute;
}

.love {
  color: #bbb;
}

/* LOGIN STYLE CSS */

.login-card {
  padding: 45px;
  margin-top: 45%;
  border-radius: 50px;
}

.login-card * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.login-card .alert {
  padding: 0 10px 0 30px;
}

.login-card h3 {
  width: 100%;
  text-align: center;
}

.login-box {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
}

a:link {
  text-decoration: none;
}

.copy {
  text-align: right;
  font-size: large;
  cursor: pointer;
}

.login-card p {
  font-size: 14px;
  line-height: 1.7;
  color: #666;
  margin: 0;
}

.login-title {
  margin-top: 5px;
  margin-bottom: 35px;
}

@media only screen and (max-height: 1100px) {
  .login-card {
    margin-top: 35%;
  }
}
@media only screen and (max-height: 700px) {
  .login-card {
    margin-top: 20%;
  }
  .login-title {
    margin-bottom: 35px;
  }
}
@media only screen and (max-height: 600px) {
  .login-card {
    margin-top: 13%;
  }
  .login-title {
    margin-bottom: 25px;
  }
  .login-card {
    padding: 30px;
  }
}
@media only screen and (max-height: 490px) {
  .login-card {
    margin-top: 7%;
  }
  .login-title {
    margin-bottom: 15px;
  }
}
@media only screen and (max-height: 470px) {
  .login-card {
    margin-top: 3%;
  }
  .login-title {
    margin-bottom: 0px;
  }
  .login-card {
    padding: 20px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (max-height: 380px) {
  .login-card {
    margin-top: 1%;
  }
  .login-title {
    margin-bottom: 0px;
  }
  .login-card {
    padding: 15px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .login-card {
    padding: 50px;
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (max-width: 500px) {
  .login-card {
    padding: 50px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 1200px) {
  .login-card {
    padding: 50px;
    padding-left: 120px;
    padding-right: 120px;
  }
}

.login-card input {
  outline: none;
  border: none;
}

.login-card textarea {
  outline: none;
  border: none;
}

.login-card textarea:focus,
.login-card input:focus {
  border-color: transparent !important;
}

.login-card button {
  outline: none !important;
  border: none;
}

button:hover {
  cursor: pointer;
}

.wrap-input100 {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 10px;
}

.input100 {
  font-size: 15px;
  line-height: 1.5;
  color: #666;
  display: block;
  width: 100%;
  background: #e6e6e6;
  height: 50px;
  border-radius: 25px;
  padding: 0 30px 0 68px;
}

.focus-input100 {
  display: block;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0 0;
  color: #f43b1c;
}

.input100:focus + .focus-input100 {
  -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
  animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
  to {
    box-shadow: 0 0 70px 25px;
    opacity: 0;
  }
}

@keyframes anim-shadow {
  to {
    box-shadow: 0 0 70px 25px;
    opacity: 0;
  }
}

.symbol-input100 {
  font-size: 18px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 35px;
  pointer-events: none;
  color: #666;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus + .focus-input100 + .symbol-input100 {
  color: #f43b1c;
  padding-left: 28px;
}

.login-logo {
  width: 100%;
  padding: 0 10%;
  margin-bottom: 30px;
}

.login100-form-btn {
  font-size: 18px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  background: #f43b1c;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  font-weight: bold;
}

.login100-form-btn:hover {
  background: #f43b1c;
}

.validate-input {
  position: relative;
}

.login-box {
  font-family: "Open Sans", sans-serif;
  padding: 38px;
  margin-top: auto;
  margin-bottom: auto;
}

.card.login-card {
  background-color: #ffffffff;
  background-color: #ffffff22;
  border-color: #f43b1c;
  border-style: solid;
  border-width: 2px;
  -webkit-box-shadow: 0px 0px 24px -1px rgba(255, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 24px -1px rgba(255, 0, 0, 0.5);
  box-shadow: 0px 0px 24px -1px rgba(255, 0, 0, 0.5);
}

/* LOGIN STYLE CSS */

.videochat {
  display: none;
}

.videochat::after {
  content: "<button className='btn btn-primary'>GUEST</button>";
}

.localvideochat {
  display: none;
}

.localvideochat::after {
  content: "<button className='btn btn-primary'>GUEST</button>";
}

.initHide {
  display: none;
}

#renderhere1,
#renderhere2 {
  display: none;
}

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 7px;
  background: #acacac;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 10px;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  background: #f43b1c;
  border-radius: 50%;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 18px;
  height: 18px;
  background: #f43b1c;
  cursor: pointer;
}

@media only screen and (min-height: 1300px) {
  .container-fluid {
    padding-top: 58px;
  }
}

video {
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 881px) {
  .container-fluid {
    width: 90%;
  }
}

.logo {
  height: 62px;
  margin-top: 20px;
  margin-bottom: 12px;
}

.logout {
  font-size: 16px;
}

.titleVideo {
  margin-top: 16px;
}

.mw-90 {
  min-width: 90px;
}

.toggle-mode {
  font-size: 0.74rem;
}

.mobile-btn {
  color: #ffffff;
  border-width: 1.4px;
  border-style: solid;
  padding: 6px 10px;
  border-radius: 7px;
  border-color: #f43b1c;
}

.rotate-btn {
  color: #fff;
  cursor: pointer;
}

.rotate-btn:hover {
  color: #f43b1c;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .container.double-player {
    padding-left: 8%;
    padding-right: 8%;
    max-width: 2560px;
  }
}

.iframe-container {
  overflow: hidden;
  /* Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 
  0.5625) */
  padding-top: 56.25%;
  position: relative;
  width: 100%;
  box-shadow: 0px 0px 30px #00000033;
}
.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 10px;
}

.full-screen-toggle {
  color: white;
  font-size: 32px;
  position: fixed;
  bottom: 12px;
  right: 22px;
  cursor: pointer;
}

#bodycenter {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 120px);
}

@media only screen and (max-width: 681px) {
  .full-screen-toggle {
    display: none;
  }
  #bodycenter {
    min-height: calc(100vh - 192px);
  }
}
