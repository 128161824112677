.text-primary1 {
  color: #fff;
}
#main {
  height: 14em;
}

#usersList,
#renderhere3,
#messages,
#allegati {
  overflow-y: auto;
  height: 18em;
}

#chatbox > .card {
  background-color: transparent;
}

#messages {
  background-color: rgba(0, 0, 0, 0.5);
  border-color: #f43b1c;
  border-style: none;
  border-width: 2px;
  border-radius: 10px;
  box-shadow: 0px 0px 30px #00000033;
}

#messages-vmix {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.form-control {
  background-color: rgba(0, 0, 0, 0.5);
  border-color: #f43b1c;
  color: #fff;
}

.form-control:focus {
  background-color: rgba(0, 0, 0, 0.7);
  border-color: #f43b1c;
  box-shadow: 0 0 0 0.2rem #dc35463f;
  color: #fff;
}

.link-section {
  margin-bottom: 5px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  left: -100px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(30, 30, 30, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fd2600de;
  border: solid 2px transparent;
  border-radius: 14px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f43b1c;
}

.msgBoxMyself {
  background-color: #ffffffff;
  color: #0a0000;
  padding: 2px 8px;
  width: 80%;
  max-width: 500px;
  margin: 6px;
  margin-left: auto;
  margin-right: 18px;
  border-radius: 12px;
  font-size: 1.1rem;
}

.msgBoxOthers {
  background-color: #ffffffff;
  color: #000000;
  padding: 2px 8px;
  width: 80%;
  max-width: 500px;
  margin: 6px;
  margin-left: 18px;
  border-radius: 12px;
  font-size: 1.1rem;
}

.msgBoxUsername {
  margin-top: 0px;
  padding-left: 6px;
  color: #f43b1c;
  /* text-decoration: underline; */
  /* font-size: 0.8em; */
  font-weight: bold;
  font-size: 0.98rem;
}
.msgBoxBody {
  margin-top: -1px;
  padding-left: 6px;
  padding-right: 16px;
  margin-bottom: 0px;
  font-size: 0.98rem;
  line-height: 0.98rem;
}
.msgBoxDate {
  margin-top: -4px;
  text-align: right;
  color: #313131;
  font-size: 0.78em;
}

.dayBox {
  background-color: #3f3f3f;
  color: #ffffff;
  padding: 4px;
  min-width: 20%;
  max-width: 35%;
  margin: 0 auto;
  text-align: center;
}

h3 {
  padding-left: 15px;
  font-size: 1.6rem;
}

.form-control.chat {
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  box-shadow: 0px 0px 30px #00000033;
}

#replyBtn {
  background-color: #000000aa;
  color: #f43b1c;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
}

.chat-container {
  max-width: 900px;
}

.general-message {
  position: relative;
}

.delete-message {
  position: absolute;
  top: 0px;
  right: 10px;
  cursor: pointer;
}

.delete-message:hover {
  color: #f43b1c;
}

@media only screen and (max-width: 480px) {
  .msgBoxMyself {
    width: 90%;
    padding: 2px 6px;
    margin-right: 9px;
    border-radius: 12px;
    font-size: 1.1rem;
  }

  .msgBoxOthers {
    width: 90%;
    padding: 2px 6px;
    margin: 6px;
    margin-left: 9px;
    font-size: 0.9rem;
  }

  .msgBoxUser name {
    margin-top: 0px;
    padding-left: 6px;
  }
  .msgBoxBody {
    margin-top: 0px;
    padding-left: 6px;
    padding-right: 16px;
    margin-bottom: 9px;
    line-height: 0.9rem;
  }
  .msgBoxDate {
    margin-top: -14px;
    font-size: 0.78em;
  }

  .dayBox {
    padding: 4px;
    min-width: 20%;
    max-width: 35%;
  }

  #messages {
    margin: 0 7%;
    height: calc(100vh - 500px);
    min-height: 200px;
  }
  .player {
    margin: 0 7%;
  }

  .toggle-mode {
    font-size: 0.6rem;
  }
}

@media only screen and (min-width: 475px) {
  #messages {
    height: calc(100vh - 560px);
    min-height: 200px;
  }
}

@media only screen and (min-width: 575px) {
  #messages {
    height: calc(100vh - 600px);
    min-height: 200px;
  }
}

@media only screen and (min-width: 768px) {
  #messages {
    height: calc(100vh - 700px);
    min-height: 200px;
  }
}

@media only screen and (min-width: 991px) {
  #messages {
    height: calc(100vh - 740px);
    min-height: 200px;
  }
}

@media only screen and (min-width: 1200px) {
  #messages {
    height: calc(100vh - 770px);
    min-height: 200px;
  }
}

#messages.full {
  height: calc(100vh - 280px);
}
